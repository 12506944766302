.products {

	margin-top: 80px;
	
	@include _992 {
		margin-top: 140px;
	}

	// .products__title

	&__title {
	}

	// .products__list

	&__list {
		margin-top: 56px;
	}

	// .products__card

	&__card {
	}
}

.products-list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	@include _768 {
		grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
		gap: 30px;
	}

	gap: 30px 15px;
}

.product-card {

	display: flex;
	flex-direction: column;

	// .product-card__image

	&__image {
		display: block;

		@media (any-hover: hover) {
			&::before {
				content: '';
				position: absolute;
				left: 50%; top: 50%;
				width: 140%; height: 140%;
				transform: translate(-50%,-50%);
				background: var(--accent-circle-gradient);
				pointer-events: none;

				opacity: 0; visibility: hidden;

				transition: opacity .3s ease, visibility .3s ease;
			}

			&:hover::before {
				opacity: 1; visibility: visible;
			}
		}
	}

	// .product-card__title

	&__title {
		margin-top: 18px;

		color: var(--white);
		font-family: var(--title-font);
		font-size: 1.125rem;

		@media (min-width: 360px) {
			font-size: 1.25rem;
		}
		font-weight: 700;
		line-height: 110%;

		max-width: 280px;

		a {
			color: var(--white);

			display: -webkit-box;
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			line-clamp: 2;
			box-orient: vertical;

			@media (any-hover: hover) {
				transition: color .2s ease;
				&:hover {
					color: var(--accent-hover);
				}
				
				&:active {
					color: var(--accent-active);
				}
			}
		}
	}

	// .product-card__price

	&__price {
		padding-top: 15px !important;
		margin-top: auto !important;

		display: flex;
		align-items: center;
		flex-direction: column;

		@media (min-width: 420px) {
			flex-direction: row;
		}

		gap: 10px;

		strong, ins {

			@media (min-width: 420px) {
				min-width: 100px;
			}
			
			color: var(--accent-2);
			font-family: var(--title-font);
			font-weight: 700;
			line-height: 110%;
			text-transform: uppercase;
			text-decoration: none;

			font-size: 1.125rem;

			@include _550 {
				font-size: 1.5rem;
			}
			
			@include _992 {
				font-size: 1.75rem;
			}
		}

		del {
			color: rgba(255, 255, 255, 0.50);
			font-family: var(--title-font);
			font-weight: 700;
			line-height: 110%;
			text-decoration-line: strikethrough;
			text-transform: uppercase;

			font-size: 0.875rem;

			@include _550 {
				font-size: 1rem;
			}
		}
	}

	// .product-card__add-cart

	&__add-cart {
		margin-top: 15px;

		width: 100%;

		svg {
			display: none;
			@include _550 {
				display: inline-block;
			}
		}
	}
}
