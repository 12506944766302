input, textarea {
	&::placeholder {
		transition: opacity .2s ease;
		opacity: 1;
	}
	
	&:focus::placeholder {
		opacity: 0;
	}
}

.input-label {
	position: relative;
	display: flex;
	&::before {
		content: '';
		position: absolute;
		left: 0; top: 0;
		width: 100%; height: 100%;
		border: 1px solid transparent;
		border-radius: 50px;
		background: linear-gradient(180deg, #9C50B8 0%, #BE9CFA 100%) border-box;
		-webkit-mask:
			linear-gradient(#fff 0 0) padding-box, 
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;

		transition: border-color .2s ease;
	}

	&.is-error {
		&::before {
			border-color: red;
		}
	}
}

input[type="password"] {
	font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
}

.is-visible-password .password-toggle {
	color: var(--accent-hover);
}

.password-toggle {
	position: absolute;
	right: 30px; top: 50%;
	transform: translate(0,-50%);

	display: flex;
	align-items: center;
	justify-content: center;

	background: transparent;
	color: var(--accent);

	transition: color .2s ease;

	@media (any-hover: hover) {
		transition: color .2s ease;
		&:hover {
			color: var(--white);
		}
		
		&:active {
			color: var(--grey);
		}
	}
}

.input {
	border-radius: 50px;
	width: 100%; min-height: 60px;
	background: rgba(35, 28, 49, 0.50);
	padding: 7px 30px;

	color: var(--white);
	font-family: var(--title-font);
	font-size: 0.875rem;
	font-weight: 700;
	line-height: normal;
	

	&::placeholder {
		color: rgba(255, 255, 255, 0.30);
		font-weight: 400;
		text-transform: none;
	}
}
