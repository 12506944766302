.footer {
	flex: 0 0 auto;
	background: var(--accent);

	padding-top: 30px; padding-bottom: 30px;

	@include _550 {
		padding-top: 76px;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	@include _992 {
		grid-template-columns: 2fr 1fr 1fr;
		padding-bottom: 86px;
	}

	gap: 0 16px;

	// .footer__logo

	&__logo {

		@include _550 {
			margin-bottom: 64px;
		}

		grid-column: 2 span;
		@include _992 {
			grid-column: 3 span;
		}
		
		margin-bottom: 30px;

		// .footer__logo--link

		&--link {
		}

		// .footer__logo--img

		&--img {
		}
	}

	// .footer__text

	&__text {
		grid-column: 2 span;
		margin-bottom: 30px;

		@include _992 {
			grid-column: auto;
			margin-bottom: 0;
		}

		color: var(--white);
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 140%;

		max-width: 410px;

		opacity: .5;
	}

	// .footer__tel

	&__tel {

		h2 {
			color: var(--white);
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 110%;
			text-transform: uppercase;

			opacity: .5;
		}

		ul {
			padding-top: 8px;
		}

		a {
			display: block;
			padding: 2px 0;

			color: var(--white);
			font-size: 1rem;
			font-weight: 600;
			line-height: 160%;
			text-transform: uppercase;

			@media (any-hover: hover) {
				transition: color .2s ease;
				&:hover {
					color: var(--accent-hover);
				}
			}
		}
	}

	// .footer__contacts

	&__contacts {

		margin-top: 30px;

		@include _550 {
			margin-top: 0;
			grid-column: auto;
		}

		@include _992 {
			grid-column: 3; grid-row: 2 span;
		}

		h2 {
			color: var(--white);
			font-size: 0.875rem;
			font-weight: 600;
			line-height: 110%;
			text-transform: uppercase;

			opacity: .5;
		}

		ul {
			padding-top: 8px;
		}

		a {
			display: block;
			padding: 2px 0;

			color: var(--white);
			font-size: 1rem;
			font-weight: 600;
			line-height: 160%;
			text-transform: uppercase;

			@media (any-hover: hover) {
				transition: color .2s ease;
				&:hover {
					color: var(--accent-hover);
				}
			}
		}
	}

	// .footer__contacts_margin

	&__contacts_margin {
		margin-top: 15px;
	}

	// .footer__social

	&__social {

		grid-column: 2 span;
		
		@include _992 {
			grid-column: 2; grid-row: 3;
			margin-top: 64px;
		}

		margin-top: 30px;

		ul {
			display: flex;
			flex-wrap: wrap;

			gap: 15px 25px;
		}

		a {
			color: var(--white);

			@media (any-hover: hover) {
				transition: color .2s ease;
				&:hover {
					color: var(--accent-hover);
				}
			}
		}
	}

	// .footer__copyright

	&__copyright {
		margin-top: 30px;
		grid-column: 2 span;
		@include _992 {
			margin-top: 65px;
			grid-column: 1; grid-row: 3;
		}

		color: var(--white);
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 140%;

		opacity: .5;
	}
}
