.header {
	position: sticky;
	background: var(--accent);
	@include _992 {
		position: relative;
		background: transparent;
	}
	transform: translate(0,-1px);
	left: 0; top: 0px;
	width: 100%;

	//padding-right: var(--popup-padding) !important;

	//transform: translate(0, var(--pos));

	//transition: transform .2s ease;

	

	z-index: 3;

	display: flex;
	flex-direction: column;

	min-width: 320px;
	
	// .header__message

	&__message {

		background: var(--accent-hover);

		display: grid;
		grid-template-rows: 1fr;

		transition: grid-template-rows .4s ease, padding .3s ease;
		&.is-hidden {
			grid-template-rows: 0fr;
			padding: 0 var(--container-padding);
		}

		padding: 7px var(--container-padding);

		// .header__message--body

		&--body {
			overflow: hidden;

			display: grid;
			grid-template-columns: auto 20px;
			@include _550 {
				grid-template-columns: 20px auto 20px;
			}

			gap: 15px;
		}

		// .header__message--text

		&--text {
			
			@include _550 {
				grid-column: 2;
			}

			display: flex;
			align-items: center;
			justify-content: center;

			gap: 7px;

			color: var(--white);
			font-family: var(--title-font);
			font-weight: 700;
			line-height: 120%;
			text-transform: uppercase;

			font-size: 0.75rem;

			@media (min-width: 1550px) {
				font-size: 0.875rem;
			}

			svg {
				flex: 0 0 25px;
			}
		}

		// .header__message--close

		&--close {
			
			@include _550 {
				grid-column: 3;
			}

			display: inline-flex;
			align-items: center;
			justify-content: center;

			background: transparent;
			color: var(--white);
		}
	}

	// .header__panel

	&__panel {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		gap: 15px 30px;

		padding-top: 10px; padding-bottom: 10px;

		background: var(--accent);
	}

	// .header__tel

	&__tel {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		flex-direction: column;

		svg {
			display: none;
		}

		padding-top: 30px;

		@include _992 {
			padding-top: 0;
			flex-direction: row;
			svg {
				display: block;
			}
		}

		gap: 15px;

		@media (min-width: 1550px) {
			gap: 30px;
		}

		li {
			display: flex;
		}

		a {
			color: var(--white);
			font-family: var(--title-font);
			font-weight: 700;
			line-height: 130%;
			text-transform: uppercase;

			@media (any-hover: hover) {
				transition: color .2s ease;
				&:hover {
					color: var(--accent-hover);
				}
				
				&:active {
					color: var(--accent-active);
				}
			}

			font-size: 1rem;
			@include _992 {
				font-size: 0.75rem;
			}

			@media (min-width: 1550px) {
				font-size: 0.875rem;
			}

			display: inline-flex;
			align-items: center;

			gap: 7px;
		}
	}

	// .header__info

	&__info {
		display: flex;
		align-items: center;

		gap: 7px;

		color: var(--white);
		font-family: var(--title-font);
		font-weight: 700;
		line-height: 130%;
		text-transform: uppercase;

		font-size: 0.75rem;

		@media (min-width: 1550px) {
			font-size: 0.875rem;
		}

		svg {
			flex: 0 0 20px;
		}

		padding: 10px 15px;

		@include _992 {
			padding: 0;

			&.is-fill-auto {
				flex: 1 1 auto;
	
				justify-content: center;
			}
		}
	}

	// .header__drop-down

	&__drop-down {

		@include _992 {
			flex: 1 1 auto;
		}

		margin-top: 25px;

		align-self: center;
		position: relative;

		font-family: var(--title-font);
		font-weight: 700;
		line-height: 130%;
		text-transform: uppercase;

		font-size: 1rem;

		@include _992 {
			font-size: 0.75rem;
			margin-top: 0;
		}

		@media (min-width: 1550px) {
			font-size: 0.875rem;
		}

		z-index: 2;

		@media (any-hover: hover) {
			&:hover & {
				&--target svg:nth-child(3) {
					color: var(--accent-hover);
				}

				&--block {
					opacity: 1; visibility: visible;
				}
			}
		}

		&.is-active & {
			&--target svg:nth-child(3) {
				color: var(--accent-hover);
			}

			&--block {
				opacity: 1; visibility: visible;
			}
		}

		// .header__drop-down--target

		&--target {
			padding: 20px 0;
			@include _992 {
				padding: 4px 0;
			}

			display: inline-flex;
			align-items: center;

			gap: 7px;

			color: var(--white);
			font-weight: 700;
			text-align: center;

			background: transparent;

			svg {
				&:nth-child(3) {
					transition: color .2s ease;
				}
			}
		}

		// .header__drop-down--block

		&--block {
			position: absolute;
			left: 0; top: 100%;
			

			@include _992 {
				top: calc(100% + 20px);
				&::before {
					content: '';
					position: absolute;
					left: 0; top: -20px;
					width: 100%; height: 20px;
				}
			}

			border-radius: 10px;
			background: var(--accent);
			padding: 10px 0;

			min-width: 79px;

			transition: opacity .3s ease, visibility .3s ease;
			opacity: 0; visibility: hidden;
		}

		// .header__drop-down--list

		&--list {
			a {
				color: var(--white);

				display: block;
				padding: 5px;
				text-align: center;

				@media (any-hover: hover) {
					transition: background .2s ease;
					&:hover {
						background: var(--accent-active);
					}
					
					&:active {
						background: var(--accent-hover);
					}
				}
			}
		}
	}

	// .header__block

	&__block {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		padding-top: 5px; padding-bottom: 5px;
		gap: 20px;

		@include _992 {
			padding-top: 30px; padding-bottom: 30px;
			gap: 0;
		}
	}

	// .header__logo

	&__logo {
		
		position: relative;
		z-index: 4;

		flex: 1 1 auto;

		display: flex;
		justify-content: flex-start;
		
		@include _992 {
			z-index: auto;
			flex: none;

			display: block;
		}

		// .header__logo--link

		&--link {
			display: block;
			//max-width: 150px;
		}

		// .header__logo--img
		
		&--img {
			object-fit: contain;
			@media (min-width: 1300px) {
				width: var(--width); height: var(--height);
			}
		}
	}

	// .header__nav

	&__nav {
		position: absolute;
		left: 0; top: 0;
		width: max(320px,100%);
		height: calc(var(--vh, 1svh) * 100);

		background: var(--primary-1);

		overflow-y: auto; overflow-x: hidden;

		display: flex;
		flex-direction: column;

		opacity: 0; visibility: hidden;

		&.is-mobile-menu-active {
			opacity: 1; visibility: visible;
		}

		transition: opacity .3s ease, visibility .3s ease;

		@include _992 {
			position: static;
			width: auto; height: auto;
			background-color: transparent;
			padding: 0;
			overflow: visible;

			opacity: 1; visibility: visible;

			flex: 1 1 auto;
			
		}

		// .header__nav--body

		&--body {
			position: relative;
			padding: var(--height-header) 0 30px;

			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			
			&::before {
				content: '';
				position: fixed;
				left: 0; top: 0;
				width: 100%; height: var(--height-header);
				background: var(--primary-1);
				z-index: 2;
			}

			@include _992 {
				padding: 0;
				flex: 0 1 auto;
				display: block;
				&::before {
					display: none;
				}
			}
		}

		// .header__nav--list

		&--list {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: center;

			@include _992 {
				align-items: stretch;
				flex-direction: row;
				justify-content: center;
			}
		}

		&--list > li {
			width: 100%;
			text-align: center;
			
			@include _992 {
				width: auto;
			}

		}

		&--list > li > a {
			width: 100%;

			padding: 15px 10px;
			display: block;

			color: var(--white);
			font-family: var(--title-font);
			font-weight: 700;
			line-height: 110%;
			// text-transform: uppercase;

			font-size: 1rem;

			@include _992 {
				font-size: 0.75rem;
				padding: 10px;
			}

			@media (min-width: 1300px) {
				font-size: 0.875rem;
			}

			@media (min-width: 1550px) {
				padding: 15px;
				font-size: 1rem;
			}

			@media (any-hover: hover) {
				transition: color .2s ease;
				&:hover {
					color: var(--accent-hover);
				}
				
				&:active {
					color: var(--accent-active);
				}
			}

			@include _992 {
				width: auto;
			}

		}
	}

	// .header__add-nav

	&__add-nav {

		display: flex;
		justify-content: center;

		@include _992 {
			display: block;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			gap: 15px;

			@media (min-width: 1550px) {
				gap: 30px;
			}
		}

		button,
		a {
			display: flex;
			align-items: center;
			justify-content: center;

			// width: 36px; height: 36px;
			// border-radius: 50%;
			// color: var(--white);
			background: transparent;

			min-width: 46px;
			min-height: 46px;
			width: auto;
			height: auto;
			border-radius: 50px;
			color: var(--white);
			// padding: 10px;

			svg {
				width: 26px; height: 26px;
			}

			span {
				max-width: 150px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: .75rem;
			}

			&[data-length] {
				position: relative;

				&::before {
					content: attr(data-length);
					position: absolute;
					right: 0; top: 0;
					transform: translate(20%,-10%);
					width: 20px; height: 20px;
					background: var(--accent-hover);
					border-radius: 50%;
			
					display: flex;
					align-items: center;
					justify-content: center;
			
					font-family: var(--title-font);
					text-align: center;
					font-size: 0.875rem;
					line-height: 100%;
					font-weight: 700;
			
					@include _992 {
						transform: translate(20%,-40%);
					}
				}
			}

			@media (min-width: 1550px) {
				width: 46px; height: 46px;
			}

			@include _992 {
				svg {
					width: 26px; height: 26px;
				}
				background: rgba(67, 50, 95, 0.80);
			}

			@media (any-hover: hover) {
				transition: background .2s ease, color .2s ease;
				&:hover {
					color: var(--white);
					background: var(--accent-hover);
				}
				
				&:active {
					background: var(--accent-active);
				}
			}
		}
	}

	// .header__add

	&__add {
		flex: 1 1 auto;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		@include _992 {
			flex: none;
			display: block;
		}
	}

	// .header__burger

	&__burger {
		position: relative;
	
		width: 30px; height: 25px;
		&::before {
			content: '';
			position: absolute;
			left: 50%; top: 50%;
			width: 150%; height: 150%;
			transform: translate(-50%,-50%);
		}
	
		background: transparent;
	
		z-index: 4;
	
		cursor: pointer;
	
		display: block;
		@include _992 {
			display: none;
		}
	
		span {
			position: absolute;
			left: 0;
	
			width: 100%; height: 3px;
			border-radius: 5px;
			background: var(--white);
			
			transition-duration: .3s;
			transition-timing-function: ease;
			transition-property: transform, top, opacity, visibility;
	
			&:nth-child(1) {
				top: 0%;
				transform: translate3d(0,0,0);
			}

			&:nth-child(2) {
				top: 50%;
				transform: translate3d(0, -50%, 0);
				opacity: 1; visibility: visible;
			}

			&:nth-child(3) {
				top: 100%;
				transform: translate3d(0, -100%, 0);
			}
		}

		&.is-mobile-menu-active span {
			&:nth-child(1) {
				top: 50%;
				transform: translate3d(0, -50%, 0) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0; visibility: hidden;
			}

			&:nth-child(3) {
				top: 50%;
				transform: translate3d(0, -50%, 0) rotate(-45deg);
			}
	
		}
	}
}
