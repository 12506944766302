
.checkbox {
	display: flex;
	gap: 10px;
}

.checkbox-input {
	position: absolute;
	width: 1px; height: 1px;
	margin: -1px; padding: 0;
	border: 0;
	
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.checkbox-input:checked + .checkbox-element {
	background: var(--accent-hover);
	color: var(--white);
}

.checkbox-element {
	cursor: pointer;
	margin-top: 2px;
	width: 19px; height: 19px; flex: 0 0 19px;

	border-radius: 4px;
	background: transparent;

	color: transparent;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		left: 0; top: 0;
		width: 100%; height: 100%;
		border: 1px solid transparent;
		border-radius: 4px;
		background: linear-gradient(180deg, #9C50B8 0%, #BE9CFA 100%) border-box;
		-webkit-mask:
			linear-gradient(#fff 0 0) padding-box, 
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
		mask-composite: exclude;
		pointer-events: none;
	}

	transition: color .2s ease, background .2s ease;

}

.checkbox-text {
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 120%;
}
