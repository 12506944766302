
*{
	padding: 0;
	margin: 0;
	border: 0;
}

*,*:before,*:after{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,:active{outline: none;}
a:focus,a:active{outline: none;}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

input[type=number], input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"] {
	-webkit-appearance: none;
	appearance: none;
}

nav,footer,header,aside{display: block;}

html,body{
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 16px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

a,button{touch-action: manipulation;}
input,button,textarea{font-family:inherit;border-radius: 0;}

input::-ms-clear{display: none;}
button{cursor: pointer;}
address {font-style: normal;}
button::-moz-focus-inner {padding:0;border:0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}
fieldset{padding: 0;}

h1,h2,h3,h4,h5,h6{font-size:inherit;font-weight: 400; margin: 0;}
