body {
	padding-right: var(--popup-padding);
	&.is-popup-active {
		overflow: hidden;
	}
}

.popup {
	position: fixed;
	left: 0; top: 0;
	width: 100%; height: 100%;

	display: flex;
	align-items: center;

	padding: 10px;

	z-index: 6;

	opacity: 0; visibility: hidden;
	transition: opacity .4s ease, visibility .4s ease;

	&.is-transition-none {
		transition: none;
	}

	&.is-active {
		opacity: 1; visibility: visible;
	}

	&.is-active-start {
		opacity: 0.0001; visibility: visible;
		&.is-active {
			opacity: 1; visibility: visible;
		}
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;

		min-height: 100%; width: 100%;

		z-index: 2;
	}
	&-bg {
		position: absolute;
		left: 0; top: 0;
		width: 100%; height: 100%;

		background: rgba(0, 0, 0, 0.71);

	}
	&-body {
		margin: auto;
		position: relative;
		background: var(--accent);
		
		max-width: 594px;
		width: 100%;

		padding: 40px 15px;
		@include _550 {
			padding: 40px 54px;
		}

		border-radius: 20px;

		z-index: 2;
	}
	&-container {
		overflow-y: auto;
		max-height: calc(var(--vh,1vh) * 100 - 120px);
		padding-bottom: 1px;
	}
	&-title {
		text-align: center;
	}
	&-text {
		padding-top: 25px;
		line-height: 120%;
		text-align: center;

		font-size: 0.875rem;
		line-height: normal;

		a {
			color: #B672CF;
			@media (any-hover: hover) {
				transition: color .2s ease;
				&:hover {
					color: var(--accent-hover);
				}
				
				&:active {
					color: var(--accent-active);
				}
			}
		}

		@include _550 {
			font-size: 1rem;
		}
	}
	&-close-btn {
		position: absolute;
		right: 5px; top: 5px;

		@include _550 {
			right: 20px; top: 20px;
		}

		line-height: 100%;
		width: 44px; height: 44px;
		color: var(--white);
		border-radius: 50px;

		@media (any-hover: hover) {
			transition: background .2s ease;
			&:hover {
				background: rgba(35, 28, 49, 0.50);
			}
			
			&:active {
				background: rgba(35, 28, 49, 0.70);
			}
		}
	  
		svg {
		  width: 50%; height: auto;
		}

		background: transparent;
	}

	&-form {
		display: flex;
		flex-direction: column;

		max-width: 376px; margin: 0 auto;

		gap: 20px;

		padding-top: 30px;

		&__text {
			text-align: center;
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 120%;

			max-width: 320px; margin: 0 auto;
			
			a {
				color: #B672CF;
				@media (any-hover: hover) {
					transition: color .2s ease;
					&:hover {
						color: var(--accent-hover);
					}
					
					&:active {
						color: var(--accent-active);
					}
				}
			}
		}
		&__checkbox {
			padding: 0 30px;

			.checkbox-element {
				background: rgba(35, 28, 49, 0.50);
			}
		}
		&__item {
			span:nth-child(1) {
				padding-left: 30px;

				font-size: 0.875rem;
				line-height: 100%;

				&.is-required::after {
					content: '*';
					padding-left: 5px;
					color: var(--accent-hover);
				}
			}
			.input-label {
				margin-top: 10px;
				.input {
					text-transform: uppercase;
				}
			}
		}
	}
}
